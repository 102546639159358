<template>
    <div class="contentEight">
        <img src="http://iv.vu818.com/img/img3-0.jpg" alt="" class="ceBg" />
        <div class="cteBox">
            <!-- 标题 -->
            <div class="steTitle">内容管理</div>
            <!-- 左边图片 -->
            <div class="cteLeftImg">
                <img class="cteImg wow animate__animated animate__slideInLeft" src="https://iv.vu818.com/img/aqimg3.png" alt="" />
            </div>
            <!-- 右边文字列表 -->
            <div class="cteRightText">
                <ul>
                    <li class="cteLi wow animate__animated animate__slideInRight">
                        <div class="clIcon">
                            <img class="ciImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">可视化内容编辑</p>
                        <p class="cteBottom">简便易操作、效果即时浏览</p>
                    </li>
                    <li class="cteLi wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        <div class="clIcon">
                            <img class="ciImg2" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">SEO管理</p>
                        <p class="cteBottom">全站人性化TDK设置，智能Sitemap生成</p>
                    </li>
                    <li class="cteLi wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        <div class="clIcon">
                            <img class="ciImg3" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">响应式后台</p>
                        <p class="cteBottom">管理界面多端适配，信息维护随心随地</p>
                    </li>
                    <li class="cteLi wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        <div class="clIcon">
                            <img class="ciImg4" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">一键多发</p>
                        <p class="cteBottom">PC、手机、小程序信息同步管理，内容一键多发，省心省力</p>
                    </li>
                    <li class="cteLi wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        <div class="clIcon">
                            <img class="ciImg5" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">权限管理</p>
                        <p class="cteBottom">按栏目、按分类，按组织架构等权限设置精细到按钮操作</p>
                    </li>
                    <li class="cteLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img class="ciImg6" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                        <p class="cteTop">站群系统</p>
                        <p class="cteBottom">分院校、子公司一站式开发，信息数据中心化储存</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentEight',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentEight {
    width: 100%;
    height: 600px;
    position: relative;
    background-color: #f6f7f7;
    .ceBg {
        position: absolute;
        top: 1%;
        right: 5%;
    }
    .cteBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // 标题
        .steTitle {
            width: 30%;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            text-align: center;
            font-weight: 900;
            margin: 0 auto;
            margin-top: 40px;
        }
        // 左边图片
        .cteLeftImg {
            width: 40%;
            height: 500px;
            // margin-top: 20px;
            float: left;
            .cteImg {
                margin: 0 auto;
                display: block;
                // margin-top: 30px;
            }
        }
        // 右边文字列表
        .cteRightText {
            width: 55%;
            height: 400px;
            position: absolute;
            bottom: 4%;
            right: -3%;
            color: gray;
            ul {
                width: 100%;
                height: 100%;
                .cteLi {
                    width: 49%;
                    height: 100px;
                    float: left;
                    position: relative;
                    .clIcon {
                        width: 50px;
                        height: 52px;
                        position: absolute;
                        top: 0;
                        left: -18%;
                        overflow: hidden;
                        .ciImg {
                            position: absolute;
                            top: -87px;
                            right: -615px;
                        }
                        .ciImg2 {
                            position: absolute;
                            top: -87px;
                            right: -674px;
                        }
                        .ciImg3 {
                            position: absolute;
                            top: -87px;
                            right: -725px;
                        }
                        .ciImg4 {
                            position: absolute;
                            top: -87px;
                            right: -780px;
                        }
                        .ciImg5 {
                            position: absolute;
                            top: -87px;
                            right: -837px;
                        }
                        .ciImg6 {
                            position: absolute;
                            top: -87px;
                            right: -900px;
                        }
                    }
                    .cteTop {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 20px;
                    }
                    .cteBottom {
                        width: 80%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
</style>
