<template>
    <div class="contentSix">
        <img src="http://iv.vu818.com/img/img1.jpg" alt="" class="csBg" />
        <div class="csBox">
            <!-- 中间文字 -->
            <div class="ctCenterText">创意设计</div>
            <!-- 4个li -->
            <div class="ctSixBox">
                <ul>
                    <li class="sixImgLi wow animate__animated animate__fadeInRight">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                    </li>
                    <li class="sixTextLi wow animate__animated animate__fadeInRight">
                        <em class="stlNum">1</em>
                        <div class="stlText">
                            <p class="sTop">AI大数据需求分析</p>
                            <p class="sBottom">根据行业及受众群体智能提供不同的解决方案</p>
                        </div>
                    </li>
                    <li class="sixImgLi wow animate__animated animate__fadeInRight">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                    </li>
                    <li class="sixTextLi wow animate__animated animate__fadeInRight">
                        <em class="stlNum">2</em>
                        <div class="stlText">
                            <p class="sTop">定制化设计</p>
                            <p class="sBottom">依据需求定制专属的趣味性个性化设计</p>
                        </div>
                    </li>
                    <li class="sixImgLi wow animate__animated animate__fadeInRight">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                    </li>
                    <li class="sixTextLi wow animate__animated animate__fadeInRight">
                        <em class="stlNum">3</em>
                        <div class="stlText">
                            <p class="sTop">可视化操作</p>
                            <p class="sBottom">操作灵活便携，即时视觉感知，云端数据存储</p>
                        </div>
                    </li>
                    <li class="sixImgLi wow animate__animated animate__fadeInRight">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                    </li>
                    <li class="sixTextLi wow animate__animated animate__fadeInRight">
                        <em class="stlNum">4</em>
                        <div class="stlText">
                            <p class="sTop">海量组件库</p>
                            <p class="sBottom">网罗前沿设计，展示灵动特效</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentSix',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentSix {
    width: 100%;
    height: 600px;
    position: relative;
    .csBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .csBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
    }
    // 中间文字
    .ctCenterText {
        width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 40px;
        font-weight: 900;
        position: absolute;
        top: 50%;
        left: 45%;
    }
    // 4个li
    .ctSixBox {
        width: 100%;
        height: 100%;
        ul {
            .sixImgLi {
                width: 100px;
                height: 100px;
            }
            .sixImgLi:nth-child(1) {
                width: 98px;
                position: absolute;
                top: 50px;
                left: 20%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: -320px;
                    right: -779px;
                }
            }
            .sixImgLi:nth-child(3) {
                width: 96px;
                position: absolute;
                top: 80px;
                right: 33%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: -320px;
                    right: -691px;
                }
            }
            .sixImgLi:nth-child(5) {
                position: absolute;
                bottom: 80px;
                right: 38%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: -320px;
                    right: -875px;
                }
            }
            .sixImgLi:nth-child(7) {
                width: 85px;
                position: absolute;
                bottom: 80px;
                left: 12%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: -320px;
                    right: -607px;
                }
            }
            // --------------------------------------
            .sixTextLi {
                width: 400px;
                height: 100px;

                .stlNum {
                    width: 20%;
                    height: 100px;
                    line-height: 100px;
                    text-align: center;
                    float: left;
                    color: #ffae4f;
                    font-size: 80px;
                }
                .stlText {
                    width: 80%;
                    height: 100px;
                    float: left;
                    .sTop {
                        width: 100%;
                        height: 60px;
                        line-height: 60px;
                        font-size: 25px;
                    }
                    .sBottom {
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                    }
                }
            }
            .sixTextLi:nth-child(2) {
                position: absolute;
                top: 140px;
                left: 17%;
            }
            .sixTextLi:nth-child(4) {
                position: absolute;
                top: 120px;
                right: 5%;
            }
            .sixTextLi:nth-child(6) {
                position: absolute;
                bottom: 80px;
                right: 7%;
            }
            .sixTextLi:nth-child(8) {
                position: absolute;
                bottom: 100px;
                left: 19%;
            }
        }
    }
}
</style>
