<template>
    <div class="contentNine">
        <img src="http://iv.vu818.com/img/img4.jpg" alt="" class="cnBg" />
        <div class="ctnBox">
            <!-- 标题 -->
            <div class="ctnTitle">系统安全</div>
            <!-- 中间图片 -->
            <div class="ctnCenter">
                <img src="https://iv.vu818.com/img/aqimg1.png" alt="" class="ctnImg wow animate__animated animate__zoomIn" />
            </div>
            <!-- 5个li -->
            <div class="ctnList">
                <ul>
                    <li class="ctnLi wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="ciImg" />
                        </div>
                        <p class="ctnTop">智能安全扫描</p>
                        <p class="ctnBottom">漏洞、病毒、敏感词等静态扫描，知名实验室系统支持</p>
                    </li>
                    <li class="ctnLi wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="ciImg2" />
                        </div>
                        <p class="ctnTop">自动备份/恢复</p>
                        <p class="ctnBottom">定期备份，随时恢复，预防误删、非法攻击等风险</p>
                    </li>
                    <li class="ctnLi wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="ciImg3" />
                        </div>
                        <p class="ctnTop">产品周期迭代</p>
                        <p class="ctnBottom">定期产品功能优化，满足更多需求，提高开发效率</p>
                    </li>
                    <li class="ctnLi wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="ciImg4" />
                        </div>
                        <p class="ctnTop">代码分析审计</p>
                        <p class="ctnBottom">定期备份，随时恢复，预防误删、非法攻击等风险</p>
                    </li>
                    <li class="ctnLi wow animate__animated animate__fadeInLeft" data-wow-delay="0.5s">
                        <div class="clIcon">
                            <img src="http://iv.vu818.com/img/vu818.png" alt="" class="ciImg5" />
                        </div>
                        <p class="ctnTop">安全检测加固</p>
                        <p class="ctnBottom">模拟用户行为进行安全检测，提供建议及加固服务</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentNine',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentNine {
    width: 100%;
    height: 750px;
    position: relative;
    .cnBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .ctnBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 标题
        .ctnTitle {
            width: 30%;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            text-align: center;
            font-weight: 900;
            margin: 0 auto;
            padding-top: 100px;
            color: white;
        }
        //  中间图片
        .ctnCenter {
            width: 550px;
            height: 400px;
            position: absolute;
            top: 24%;
            left: 29%;
        }
        // 5个li
        .ctnList {
            width: 100%;
            height: 100%;
            position: relative;
            ul {
                .ctnLi {
                    width: 250px;
                    height: 100px;
                    color: white;
                    position: relative;
                    .clIcon {
                        position: absolute;
                        top: 0;
                        left: -22%;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        .ciImg {
                            position: absolute;
                            top: -88px;
                            left: -538px;
                        }
                        .ciImg2 {
                            position: absolute;
                            top: -88px;
                            left: -479px;
                        }
                        .ciImg3 {
                            position: absolute;
                            top: -88px;
                            left: -418px;
                        }
                        .ciImg4 {
                            position: absolute;
                            top: -88px;
                            left: -358px;
                        }
                        .ciImg5 {
                            position: absolute;
                            top: -88px;
                            left: -297px;
                        }
                    }
                    .ctnTop {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 20px;
                        font-weight: 900;
                    }
                    .ctnBottom {
                        width: 100%;
                        height: 50px;
                        line-height: 25px;
                        font-size: 16px;
                    }
                }
                .ctnLi:nth-child(1) {
                    position: absolute;
                    top: 10%;
                    left: 15%;
                }
                .ctnLi:nth-child(2) {
                    position: absolute;
                    top: 40%;
                    left: 12%;
                }
                .ctnLi:nth-child(3) {
                    position: absolute;
                    bottom: 25%;
                    left: 550px;
                }
                .ctnLi:nth-child(4) {
                    position: absolute;
                    bottom: 45%;
                    right: 5%;
                }
                .ctnLi:nth-child(5) {
                    position: absolute;
                    top: 10%;
                    right: 10%;
                }
            }
        }
    }
}
</style>
